<template>
  <div>
    <p class="alert alert-primary mb-3">
      <i class="far fa-info me-2"></i>From here you can manage your library of
      frequently used supplements.
    </p>

    <div class="row mb-3">
      <div class="col">
        <form @submit.prevent="addNewSupplement">
          <div class="row mb-3">
            <div class="col">
              <label for>Title</label>
              <input
                type="text"
                v-model="form.title"
                class="form-control"
                placeholder="Title"
                required
              />
            </div>

            <div class="col">
              <label for>SKU / Code</label>
              <input
                type="text"
                v-model="form.sku"
                class="form-control"
                placeholder="SKU / Code"
              />
            </div>
          </div>

          <!--  -->

          <div class="row mb-3">
            <div class="col">
              <label for>Category</label>
              <select
                placeholder="Select Category"
                class="form-control"
                v-model="form.category_id"
              >
                <option v-for="c in categories" :key="c.id" :value="c.id">
                  {{ c.name }}
                </option>
              </select>
            </div>

            <div class="col">
              <label for>Price</label>
              <input
                type="text"
                v-model="form.price"
                class="form-control"
                placeholder="Price"
              />
            </div>

            <div class="col">
              <label for>Dosage</label>
              <input
                type="text"
                v-model="form.dosage"
                class="form-control"
                placeholder="Dosage"
              />
            </div>
          </div>

          <!--  -->

          <div class="row mb-3">
            <div class="col">
              <label for>Comments</label>
              <textarea
                v-model="form.comments"
                class="form-control"
                placeholder="Comments"
                rows="3"
              ></textarea>
            </div>
          </div>

          <div class="row">
            <div class="col-auto ms-auto">
              <button class="btn btn-primary" type="submit">
                <i class="far fa-check me-2"></i>Add Supplement
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col-auto my-auto align-items-center form-inline">
        <select
          class="form-control form-control-sm me-2"
          v-model="searchType"
        >
          <option value="title">Title</option>
          <option value="category">Category</option>
          <option value="sku">SKU</option>
        </select>
        <input
          type="text"
          v-model="search"
          class="form-control form-control-sm me-2"
          autocomplete="offington"
          placeholder="Enter search query"
        />
      </div>
      <div class="col-auto ms-auto form-inline">
        <div class="m-4">
          <button
            class="btn btn-sm btn-outline-dark me-2"
            :class="desc == false ? 'active' : ''"
            @click="desc = false"
          >
            <i class="far fa-sort-size-up"></i>
          </button>
          <button
            class="btn btn-sm btn-outline-dark"
            :class="desc == true ? 'active' : ''"
            @click="desc = true"
          >
            <i class="far fa-sort-size-down"></i>
          </button>
        </div>
        <div class="dropdown">
          <button
            class="btn btn-light btn-sm dropdown-toggle"
            type="button"
            id="sortingDropdown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="far fa-sort me-2"></i>
            <span class="text-capitalize">{{ sorting | formatLabel }}</span>
          </button>
          <div class="dropdown-menu" aria-labelledby="sortingDropdown">
            <button
              class="dropdown-item"
              type="button"
              @click="sortBy('sku')"
            >
              SKU
            </button>
            <button
              class="dropdown-item"
              type="button"
              @click="sortBy('title')"
            >
              Title
            </button>
            <button
              class="dropdown-item"
              type="button"
              @click="sortBy('price')"
            >
              Price
            </button>
            <button
              class="dropdown-item"
              type="button"
              @click="sortBy('created_at')"
            >
              Created At
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <table class="table table-sm table-hover longTextWrap">
          <thead>
            <tr>
              <th scope="col">SKU</th>
              <th scope="col">Title</th>
              <th scope="col">Category</th>
              <th scope="col">Price</th>
              <th scope="col">Dosage</th>
              <th scope="col">Comments</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="p in searchSupplements" :key="p.id">
              <th scope="col">
                <span v-if="editSupplement !== p">{{
                  p.sku | formatSku
                }}</span>
                <span v-else>
                  <input
                    type="text"
                    v-model="p.sku"
                    class="form-control"
                    @change="updateSupplement(false)"
                  />
                </span>
              </th>
              <td>
                <span v-if="editSupplement !== p">{{ p.title }}</span>
                <span v-else>
                  <input
                    type="text"
                    v-model="p.title"
                    class="form-control"
                    @change="updateSupplement(false)"
                  />
                </span>
              </td>
              <td>
                <span v-if="editSupplement !== p">{{
                  categoryByID(p.category_id)
                }}</span>
                <span v-else>
                  <select
                    placeholder="Select Category"
                    class="form-control"
                    v-model="p.category_id"
                  >
                    <option v-for="c in categories" :key="c.id" :value="c.id">
                      {{ categoryByID(c.id) }}
                    </option>
                  </select>
                </span>
              </td>
              <td>
                <span v-if="editSupplement !== p">£{{ p.price }}</span>
                <span v-else>
                  <input
                    type="text"
                    v-model="p.price"
                    class="form-control"
                    @change="updateSupplement(false)"
                  />
                </span>
              </td>
              <td>
                <span v-if="editSupplement !== p">{{ p.dosage }}</span>
                <span v-else>
                  <input
                    type="text"
                    v-model="p.dosage"
                    class="form-control"
                    @change="updateSupplement(false)"
                  />
                </span>
              </td>
              <td>
                <span v-if="editSupplement !== p">{{ p.comments }}</span>
                <span v-else>
                  <input
                    type="text"
                    v-model="p.comments"
                    class="form-control"
                    @change="updateSupplement(false)"
                  />
                </span>
              </td>
              <td class="text-end">
                <span v-if="editSupplement !== p">
                  <button
                    class="btn btn-light btn-xs me-1"
                    @click="editSupplement = p"
                  >
                    <i class="far fa-pencil"></i>
                  </button>
                  <button
                    class="btn btn-light btn-xs"
                    @click="deleteSupplement(p)"
                  >
                    <i class="far fa-trash"></i>
                  </button>
                </span>
                <span v-else>
                  <button
                    class="btn btn-outline-danger btn-xs me-1"
                    @click="editSupplement = null"
                  >
                    <i class="far fa-times"></i>
                  </button>
                  <button
                    class="btn btn-primary btn-xs"
                    @click="updateSupplement(true)"
                  >
                    <i class="far fa-save"></i>
                  </button>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["path", "user"],
  data() {
    return {
      supplements: [],
      categories: [],
      form: {
        clinic_id: this.user.clinic.id,
        user_id: this.user.id,
        sku: "",
        title: "",
        description: "",
        dosage: "",
        comments: "",
        price: "0",
      },
      editSupplement: null,
      desc: false,
      sorting: null,
      search: "",
      searchType: "title",
    };
  },
  methods: {
    categoryByID(id) {
      var category = "Uncategorised";
      this.categories.filter(function (obj) {
        if (obj.id == id) {
          category = obj.name;
          return true;
        }
      });

      return category;
    },
    getCategories() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/supplements/categories").then(({ data }) => {
        this.categories = data.data;
      });
    },
    sortBy(sortKey) {
      this.sorting = sortKey;
    },
    fetchSupplements() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/supplements").then(({ data }) => {
        this.supplements = data;
      });
    },
    addNewSupplement() {
      this.$axios.post(process.env.VUE_APP_API_URL + "/supplements", this.form).then(({ data }) => {
        this.form = {
          clinic_id: this.user.clinic.id,
          user_id: this.user.id,
          sku: "",
          title: "",
          description: "",
          dosage: "",
          comments: "",
          price: "0",
        };
        this.fetchSupplements();
        this.$EventBus.$emit("alert", data);
      });
    },
    deleteSupplement(supplement) {
      if (
        confirm(
          "Are you sure you wish to delete this supplement, this action cannot be undone!?"
        )
      ) {
        this.$axios.delete(process.env.VUE_APP_API_URL + "/supplements/" + supplement.id).then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.fetchSupplements();
        });
      }
    },
    updateSupplement(close) {
      this.$axios
        .put(process.env.VUE_APP_API_URL + "/supplements/" + this.editSupplement.id, this.editSupplement)
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          if (close) {
            this.editSupplement = null;
          }
        });
    },
  },
  computed: {
    orderedSupplements: function () {
      if (this.desc == false) {
        return _.orderBy(this.supplements, this.sorting);
      } else {
        return _.orderBy(this.supplements, this.sorting).slice().reverse();
      }
    },
    searchSupplements() {
      if (this.search && this.search.length > 0) {
        return this.orderedSupplements.filter((item) => {
          if (this.searchType == "title") {
            return this.search
              .toLowerCase()
              .split(" ")
              .every((v) => item.title.toLowerCase().includes(v));
          } else if (this.searchType == "sku") {
            return this.search
              .toLowerCase()
              .split(" ")
              .every((v) => item.sku.toLowerCase().includes(v));
          }
          else if (this.searchType == "category") {
            return this.search
              .toLowerCase()
              .split(" ")
              .every((v) => this.categoryByID(item.category_id).toLowerCase().includes(v));
          }
        });
      } else {
        return this.orderedSupplements;
      }
    },
  },
  mounted() {
    this.fetchSupplements();
    this.getCategories();
  },
  filters: {
    formatSku(thing) {
      return thing ? thing : "-";
    },
    formatLabel(str) {
      if (!str) {
        return "Sorting";
      } else {
        return str.replace(/_/g, " ");
      }
    },
  },
};
</script>

<style scoped>
.longTextWrap {
  table-layout: fixed;
  word-wrap: break-word;
}
</style>