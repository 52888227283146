<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h4 class="fw-bold text-primary">Supplements</h4>
        <ul class="nav nav-tabs mb-3">
          <li class="nav-item me-2" @click="handlePage(0)">
            <a href="#Supplements" :class="viewPage == 0 ? 'nav-link active' : 'nav-link'">Supplements</a>
          </li>
          <li class="nav-item me-2" @click="handlePage(1)">
            <a href="#Categories" :class="viewPage == 1 ? 'nav-link active' : 'nav-link'">Categories</a>
          </li>
        </ul>
        <div v-if="viewPage == 0">
          <supplements-index :path="path" :user="user"></supplements-index>
        </div>
        <div v-if="viewPage == 1">
          <supplements-categories></supplements-categories>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import SupplementsCategories from "./supplements-partials/categories";
import SupplementsIndex from "./supplements-partials/supplements";

export default {
  props: [],
  data() {
    return {
      viewPage: 0,
    };
  },
  methods: {
    handlePage(index) {
      this.viewPage = index;
    },
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    path() {
      return this.$stash.path;
    }
  },
  components: {

    SupplementsCategories,
    SupplementsIndex
  },
};
</script>
<style>
</style>